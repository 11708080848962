@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

*{
  box-sizing: border-box;
}

html{
  box-sizing: border-box;
  width: 100%;
  display:block;
  flex-shrink: 0;
}
body {
  background-color: #f5f5f5;
  min-height: 100%;
  box-sizing: border-box;
  margin: 0;
  display: block ;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  }

main {
  background-image: url("/public/images/bg/bg-2.jpg");
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  scrollbar-width: none;
  overflow: hidden;
}





.panel-light {
  background-color: rgba(255, 255, 255, 0.5); /* Light background color with 50% opacity */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4); /* Shadow effect */
  backdrop-filter: blur(5px);
  position: relative;
}

.panel-dark {
  background-color: rgba(0, 0, 0, 0.5); /* Dark background color with 50% opacity */
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow effect */
  backdrop-filter: blur(5px);
    position: relative;

}

.button {
  background-color: #aeaeaeaa; /* Button background color */
  color: #000000; /* Button text color */
  border: none; /* Remove button border */
  border-radius: 5px; /* Rounded button corners */
  padding: 10px 20px; /* Button padding */
  cursor: pointer; /* Change cursor to pointer on hover */
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #949292;
  font-size: .8rem;
  font-weight: 600;
  text-transform: uppercase;
}


.button:hover {
  background-color: #5f5f5f; /* Darken the button background color on hover */
  color: #ffffff; /* Change the button text color on hover */
}

.uppercase{
      text-transform: uppercase;
}

.transition-500{
  transition: all 0.5s ease;
}

/* scale animation */
.btn-text{
  scale: 1;
  animation: animate-scale 0.5s ease;
}

@keyframes animate-scale {
  0% {
    transform: scale(0.1);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}


.gradientBorder{
  border-image: linear-gradient( 90deg ,#356f94, #e1d605 ) 1;
  border-width: 2px;
  border-style: solid;
backdrop-filter: blur(5px);
background-color: rgba(255, 255, 255, 0.5);
}




.w-100{
  width: 100px;
  max-width: 100px;
}

.w-600{
  width: 620px;
  max-width: 620px;
}
.w-700{
  width: 700px;
  max-width: 700px;
}

.w-900{
  width: 900px;
  max-width: 900px;
}

.h-900{
  height: 505px;
  max-height: 900px;
  display: flex;

}
.h-500{
  height: 500px;
  max-height: 500px;
  display: flex;

}
.h-550{
  height: 550px;
  max-height: 550px;
  display: flex;

}
.h-600{
  height: 600px;
  max-height: 600px;
  display: flex;

}
.h-700{
  height: 700px;
  max-height: 700px;
  display: flex;

}

.login-container{
  width:600px;
  max-width: 600px;
}

.login-wrapper{
  width:900px;
  max-width: 900px;
}

.login-wrapper-translate{
  transform: translateX(-195px);
}
.login-left, .login-right{
  width: 200px;
}

.login-middle{
  width: 400px;
}

.login-form{
  height: 500px;
}

.formClass form{
  height: 300px;
  min-height: 300px;
  max-height: 300px;
}





.scaleAnimated{
  background-color: gray;
 animation-name: scale; 
  animation-duration: 1000ms; 
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1000ms;
}

@keyframes scale {
  0% {
    background-color: gray;
    transform: scale(1);
  }
  50% {
    background-color: gray;
    transform: scale(1.2);
  }
  100% {
    background-color: #1E40AF;
    transform: scale(1);
  }
}

.gradientBackground{
  background-image: linear-gradient(90deg, rgb(148, 141, 245) 0%, rgb(43, 77, 170) 35%, rgb(5, 15, 125) 100%)
}


.text-2xs{
  font-size: 0.625rem;
}
.text-3xs{
  font-size: 0.5rem;
}